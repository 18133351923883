@include media(xl) {
    .m-product-list-item {
        &__details {
            column-gap: 6 * $space;
        }
        &__title {
            height: 4 * $line-height--regular;
            overflow: hidden;
        }
        &__subtitle {
            height: 2 * $font-size--regular-plus;
            overflow: hidden;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1221";