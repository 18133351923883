@include media(lg) {
    .m-product-list-item {
        column-gap: 6 * $space;
        align-items: center;

        &__details {
            grid-template-columns: auto auto;
            align-items: center;
            column-gap: 14 * $space;
        }
        &__price {
            text-align: right;
        }
        &__description {
            grid-template-rows: min-content;
            row-gap: 2 * $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1217";