//Refactors: static/assets/Yves/styles/v2/elements/_tables.scss

@include media(lg) {
    .m-table {
        &--md-compact {
            padding: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1054";