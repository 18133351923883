@include media(lg) {
    .o-main-navigation {
        &__container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 8 * $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1128";