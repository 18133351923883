.cat-filters {
    background-color: $grey--light;
    left: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;

    &-details {
        height: calc(var(--vh, 1vh) * 100 - 124px);
        display: none;
    }

    @include media(lg) {
        background-color: white;
        height: auto;
        left: 0;
        overflow: visible;
        position: relative;
        width: 100%;
    }

    .show-filters-details &-details {
        display: block;
    }

    .show-filters & {
        background-color: rgba(0, 0, 0, 0.3);
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        left: 100%;
        top: 0;
        z-index: $z5;

        &__header,
        &__footer,
        &__content {
            background-color: white;
            position: fixed;
            margin-left: 48px;
            width: calc(100% - 48px);
        }

        &__header {
            height: 48px;
        }

        &__footer {
            top: calc(var(--vh, 1vh) * 100 - 220px);
            height: 70px;
            box-shadow: $shadow8;
        }

        &__content {
            top: 47px;
            height: calc(var(--vh, 1vh) * 100 - 267px);
            width: calc(100% - 48px);
            overflow: auto;
        }

        .close-filters {
            display: flex;
        }

        .filter-save,
        .filter-cancel,
        .filter-loading {
            top: calc(var(--vh, 1vh) * 100 - 205px);
            width: calc(100% - 30px);
            margin: $space * 3;
        }

        .filter-details__item[disabled] {
            color: $grey;
        }

        &-details {
            position: absolute;
            width: 100%;
            height: calc(var(--vh, 1vh) * 100);
            left: 200%;
            z-index: $z-modal;

            .close-filter-details {
                background-color: $grey--light;
                position: fixed;
                left: 0;
                top: 0;
                width: 48px;
                height: 48px;
                border: 0;
                border-radius: 0;
            }

            &__header,
            &__content {
                background-color: white;
                position: fixed;
                margin-left: 48px;
                width: calc(100% - 48px);
            }

            &__header {
                height: 48px;
            }

            &__content {
                top: 48px;
                height: calc(var(--vh, 1vh) * 100 - 267px);
            }

            &__footer {
                background-color: white;
                position: fixed;
                width: 100%;
                box-shadow: $shadow8;
                height: 70px;
                top: calc(var(--vh, 1vh) * 100 - 220px);
            }

            .cat-filter__content {
                max-height: calc(var(--vh, 1vh) * 100 - 267px);
            }

            .form__field--checkbox {
                min-width: 100%;
                margin: 0;
                padding: 0 ($space * 3);
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;

                @include border($where: bottom);
            }

            .checkbox-label {
                margin: 0;
                padding: ($space * 3) 0;
                flex-grow: 1;
                max-width: 80%;
                order: 1;
            }

            .checkbox-btn {
                right: 0;
                left: auto;
                margin-right: $space * 3;
                order: 4;
            }

            .checkbox-count {
                margin-right: $space * 2;
                text-align: right;
                order: 2;
            }

            .checkbox-input {
                order: 3;
            }

            .filter-sorting {
                width: calc(100% - 48px);
            }

            .filter-range {
                width: 100%;
            }

            .range-slider {
                padding: $space * 3;
            }

            .fa-search {
                right: 0;
                padding-right: 30px;
                font-size: $font-size--medium;
            }

            .filter-save,
            .filter-cancel,
            .filter-loading {
                width: calc(100% - 78px);
            }
        }
    }

    .close-filters {
        background-color: $grey--light;
        position: fixed;
        left: 0;
        top: 0;
        width: 48px;
        height: 48px;
        display: none;
        border: 0;
        border-radius: 0;
    }

    .opened .cat-filter__btn {
        @include media(lg) {
            background-color: #fff;
            box-shadow: $shadow1;
            justify-content: left !important;
            position: relative;
            z-index: $z2;
            &::after {
                background-color: #fff;
                bottom: -$space;
                content: "";
                height: $space;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        & > .txt--truncate {
            max-height: 36px;
            white-space: normal;
            word-wrap: break-word;
            z-index: $z1;
        }
    }

    &__container {
        background-color: white;
        position: relative;
        top: 0;

        @include media(lg) {
            margin: 0 (-$space);
            top: 0;
        }
    }

    &__reset {
        padding: $space * 3;
    }

    &__reset > .fa {
        right: $space * 2;
    }

    &__tag {
        margin: $space $space 0 0;
    }

    &__title {
        margin: $space * 2;

        @include media(lg) {
            margin: 0 0 $space * 2;
            min-height: 36px;
        }
    }
}

.cat-filter {
    // TODO BOSS-34571 style
    &_mobile-scrolls {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin: $space * 2;
        @include media(lg) {
            display: none;
        }
    }

    // TODO BOSS-34571 style
    &_mobile-scrolls-item {
        padding: 0 $space*3;
        border-radius: 20px;
        margin-right: $space;
        &:last-child {
            margin-right: 0;
        }
    }

    @include media(lg) {
        width: auto;
    }

    &__container {
        @include media(lg) {
            position: absolute;
            width: 21em;
        }

        .fa-search {
            right: $space * 2;
        }

        .button {
            height: 32px;
        }
    }

    &--more {
        display: none;
    }

    &__content {
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;

        @include media(lg) {
            max-height: 220px;
        }
    }

    &__btn {
        border-radius: 0;
        border-top-width: 0;
        font-weight: $font-weight--base;
        justify-content: left !important;
        padding: 5px 12px 6px;
        position: relative;
        text-align: left;

        @include media(lg) {
            border-radius: $border-radius;
            border-top-width: 1px;
            height: 32px;
        }

        .txt--truncate {
            overflow: unset;
            margin-right: 10px;
        }

        & > .fa {
            right: 0;
        }
    }

    &:nth-child(3n + 2) > .cat-filter__container {
        @include media(lg) {
            right: $space;
        }
        @include media(xl) {
            right: auto;
        }
    }

    &:nth-child(4n + 2) > .cat-filter__container {
        @include media(xl) {
            right: $space;
        }
        @include media(xxl) {
            right: auto;
        }
    }

    &:nth-child(6n + 2) > .cat-filter__container {
        @include media(xxl) {
            right: $space;
        }
    }

    &__filter-mobile-buttons {
        position: sticky;
        top: 0;
        z-index: 200;
        padding: 0 2 * $space;
        height: 70px;
        display: flex;
        align-items: center;
        background: white;
        gap: 2 * $space;

        @include media(lg) {
            visibility: hidden;
            display: none;
        }

        &.-control,
        &.-non-sticky,
        &.-sticky {
            position: static;
        }
    }
}

.cat-filters--more-hidden {
    .more-than-five {
        @include media(lg) {
            .cat-filter--more {
                display: block;
            }

            .hidden_lg,
            .hidden_xl {
                display: none;
            }
        }
    }

    .more-than-nine {
        @include media(xl) {
            .hidden_lg {
                display: none;
            }

            .hidden_xl {
                display: none;
            }
        }

        @include media(xxl) {
            .cat-filter--more {
                display: block;
            }

            .hidden_lg {
                display: block;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:791";