.m-tag {
    background: #fff;
    font-size: $font-size--regular;
    min-height: 26px;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    border: 1px solid;
    padding: 0 $space;
    cursor: pointer;

    &.-primary {
        color: $blue;
        border-color: $blue;

        &:hover {
            background: $blue;
            color: #fff;
        }
    }

    &.-secondary {
        color: $grey--darker;
        border-color: $grey--dark;

        &:hover {
            background: $grey--medium;

        }
    }

    &__remove-icon {
        height: 14px;
        margin-left: $space;
    }

}

;@import "sass-embedded-legacy-load-done:1213";