@mixin collapsible($break-point) {
    .m-collapsible {
        &__target--#{$break-point} {
            display: block;
        }

        &__target--#{$break-point}.collapsed {
            display: none;
        }

        &__target:not(.m-collapsible__target--#{$break-point}) {
            &.collapsed {
                display: block;
            }
        }

        &__source--#{$break-point}.collapsed {
            cursor: pointer;
            .fa-angle-up {
                display: block;
                transform: rotate(180deg);
            }
        }

        &__source:not(.m-collapsible__source--#{$break-point}) {
            cursor: initial;
            .fa-angle-up {
                display: none;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:996";