//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT Image with Badges
\* ------------------------------------ */

@include media(lg) {
    .m-product-image {
        &__badge-container {
            display: flex;
            flex-direction: row;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__badge--first {
            margin-bottom: 0;
        }
    }
}


;@import "sass-embedded-legacy-load-done:1030";