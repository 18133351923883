.m-product-delivery-time-v2 {
    &__container {
        li {
            list-style: none;
            display: flex;
        }
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: $space * 2;
        padding: 2px 0;
        width: 18px;
        height: 18px;
    }

    &__list-container {
        list-style-type: disc;
    }

    &__list {
        padding-bottom: $space;

        &::marker {
            font-size: $font-size--medium;
            color: $grey--dark;
        }
        &--express {
            &::marker {
                color: $green;
            }
        }
    }

    &__delivery-date-only {
        display: flex;

        .m-product-delivery-time-v2__icon {
            flex-shrink: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1070";