/* Headline classes */
@include generate-breakpoint-prefixes($class-name: '_h', $short-name: true) {
    &1 {
        font-size: $font-size--xlarge;
        line-height: $line-height--xlarge;

        /* Extra large h1 (mostly for CMS pages) */
        &--res {
            font-size: 36px;

            @include media(md) {
                font-size: 48px;
                line-height: 48px;
            }

            @include media(lg) {
                font-size: 60px;
                line-height: 60px;
            }
        }
    }

    &2 {
        font-size: $font-size--large;
        line-height: $line-height--large;

        /* Extra large h2 (mostly for CMS pages) */
        &--res {
            font-size: $font-size--regular-plus;

            @include media(md) {
                font-size: $font-size--medium;
            }

            @include media(xl) {
                font-size: $font-size--large;
            }
        }
    }

    &3 {
        font-size: $font-size--medium;
        line-height: $line-height--regular;
    }

    &4 {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
    }

    &5 {
        font-size: $font-size--regular;
        line-height: $line-height--regular;
    }

    &6 {
        font-size: $font-size--small;
        line-height: $line-height--small;
    }
}

/* Headline tags */
h1 {
    @extend ._h1;
}

h2 {
    @extend ._h2;
}

h3 {
    @extend ._h3;
}

h4 {
    @extend ._h4;
}

h5 {
    @extend ._h5;
}

h6 {
    @extend ._h6;
}

h1.txt--main,
h2.txt--main,
h3.txt--main,
h4.txt--main,
h5.txt--main,
h6.txt--main,
._h1.txt--main,
._h2.txt--main,
._h3.txt--main,
._h4.txt--main,
._h5.txt--main,
._h6.txt--main {
    font-weight: $font-weight--base !important;
}

/* Raw HTML */
.html--raw {
    h2,
    h3 {
        font-weight: $font-weight--bold;

        &.txt--main {
            font-weight: $font-weight--base;
        }
    }

    h1 {
        @extend ._h2;
        font-family: $font-family--main;
        font-weight: $font-weight--base;
        margin: ($space * 2) 0;
    }

    h2 {
        margin: ($space * 2) 0;
    }

    h3 {
        margin: $space 0;
    }
}

;@import "sass-embedded-legacy-load-done:304";