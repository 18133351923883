//TODO Deprecated: Code usages are already removed, only need to make sure there are no usges in cms pages and delete this file.
//Refactored Version: static/assets/Yves/styles/v2/components/_product-history.organism.scss

/* ------------------------------------ *\
    PRODUCT HISTORY
\* ------------------------------------ */

.product-history {
    .subheadline {
        border-bottom: 0;

        @include media(lg) {
            @include border(bottom, $color: $grey);
        }
    }

    &__item {
        flex: 0 0 154px;
        flex-wrap: wrap;
        position: relative;
        padding: 0 ($space * 2);

        &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            height: 40px;
            font-weight: $font-weight--bold;
        }

        &-link {
            height: 80px;
        }

        @include media(xl) {
            display: flex;
            flex-wrap: wrap;
            padding: $space * 2;
        }
    }

    &__price {
        width: 100%;
    }

    &__slider {
        display: flex;
        flex-wrap: nowrap;
        padding-top: $space * 2;

        .slider__content {
            padding-bottom: $space * 4;

            @include media(lg) {
                margin: 0 $space * 8;
            }
        }

        @include media(xl) {
            padding-bottom: 0;
        }
    }

    &__erase {
        font-size: $font-size--regular;
        font-weight: $font-weight--bold;

        .fa {
            font-size: $font-size--regular;
            font-weight: $font-weight--bold;
        }
    }
}

;@import "sass-embedded-legacy-load-done:454";