.m-sponsored-label {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
        margin-right: 3px;
    }

    &__info {
        cursor: pointer;
    }

    &__icon {
        color: $grey;
    }
}

;@import "sass-embedded-legacy-load-done:1068";