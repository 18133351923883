.ca-order-state {
    position: relative;
    display: flex;
    padding: 7px;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: $grey--light;
    overflow: hidden;

    .fa {
        display: none;
        color: $grey;
    }

    &__icon {
        position: relative;
        z-index: 20;
        display: flex !important;
        min-width: 36px;
        max-width: 36px;
        height: 36px;
        margin-right: 10px;
        background-color: #fff;
        border-radius: 36px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    &__text {
        display: none;
        flex-grow: 1;
        margin-right: $space * 2;
        font-weight: $font-weight--bold;
        font-size: $font-size--medium;
        color: $grey--dark;
    }

    &--reached {
        background-color: $color--success;

        .fa,
        .ca-order-state__text {
            color: #fff;
        }

        .ca-order-state__icon {
            color: $color--success !important;
        }
    }

    &--current {
        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            right: -1px;
            width: 10px;
            height: 100%;
            background-color: $grey--light;
        }

        &::before {
            bottom: 50%;
            transform: rotate(-20deg);
        }

        &::after {
            top: 50%;
            transform: rotate(20deg);
        }

        &:last-child {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}

@include media(xxl) {
    .ca-order-state {
        padding: 7px 20px;
        justify-content: space-between;

        .fa,
        &__text {
            display: block;
        }

        &--reached {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:677";