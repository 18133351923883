.navigation-mobile {
    color: $base-text-color;
    display: block;
    top: 0;
    min-width: 320px;
    max-width: 768px;
    z-index: $z4;
    font-size: $font-size--regular;
    line-height: $line-height;
    text-align: left;
    left: -100vw;
    position: absolute;
    width: 100vw;
    height: 100vh;

    &.active {
        transform: translateX(100%);
        transition: transform 300ms ease-in-out;

        & .menu {
            display: block;
        }
    }

    .menu {
        display: none;

        &__overview,
        &__categories1,
        &__categories2,
        &__brands,
        &__offers {
            top: 0;
            position: fixed;
            background-color: white;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        &__categories1,
        &__categories2,
        &__brands,
        &__offers {
            transform: translateX(100%);
            transition: transform 300ms ease-in-out;
            padding-bottom: $space * 14;
        }

        &__categories1.show,
        &__categories2.show,
        &__brands.show,
        &__offers.show {
            transform: translateX(0);
            transition: transform 300ms ease-in-out;
        }

        &__categories1 {
            z-index: $z2;
        }

        &__categories2 {
            z-index: $z3;
        }

        &__brands,
        &__offers {
            z-index: $z1;
        }

        &__user {
            margin: 0 ($space * 6);
            padding: ($space * 6) 0;

            @include border(bottom, $color: $grey--light);
            @include border(top, $color: $grey--light);

            &--logged-in {
                border-bottom: none;
            }

            .m-menu-item {
                padding-left: 0;
            }

            .m-menu-item__text {
                padding-left: $space * 2;
            }
        }

        &__close {
            position: fixed;
            right: 0;
            width: 80px;
            min-width: 80px;
            height: 80px;
            padding: $space * 6;
            z-index: $z5;
        }

        &__brands--header.m-menu-item__headline {
            height: 50px;
        }
    }

    &.active {
        display: block;
    }

    .m-menu {
        &-item {
            padding-left: $space * 6;
            width: 100%;
            min-width: 100%;

            &[class*=fa-] a {
                margin-left: 0;
            }

            &__text {
                padding-left: 0;
            }

            &__text span {
                text-overflow: ellipsis;
                padding-right: 0;
                width: 100%;
            }

            &[class*=fa-]::before,
            &[class*=icon--]::before {
                margin-left: 0;
                font-size: $font-size--large;
                position: static;
            }

            &__bordered {
                padding-right: $space * 6;
            }

            &__headline {
                padding-right: $space * 6;
            }
        }

        &-header {
            &__close-button {
                height: 80px;
                position: fixed;
                z-index: $z5;
            }

            & .m-menu-item__headline {
                padding-top: $space * 6;
                padding-left: $space * 6;
            }
        }
    }

    .m-pictures-menu {
        &__container {
            margin: 0 ($space * 6) ($space * 8);
        }

        &__item {
            margin-left: 0;
            margin-right: $space * 6;
            width: calc((100% - 30px) / 2);
            height: 70px;
            text-align: center;

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        &__button {
            width: 100%;
            margin: 0 ($space * 6);
        }
    }

    .m-customer-type-switch--wrapper {
        border: 0;
    }

    .o-menu.show {
        width: 100%;
    }

    .a-switch__label {
        margin-bottom: $space * 6;
    }
}

;@import "sass-embedded-legacy-load-done:880";