@include media(lg) {
    .a-divider {
        &__container {
            margin-bottom: $space * 20;
        }

        &__icon,
        &__icon.fa {
            width: $space * 14;
            height: $space * 14;
            font-size: $space * 14;
            line-height: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:967";