.whf-detail__scores-table {
    position: absolute;
    top: -270px;
    background: #1d99fa;
    color: #fff;
    padding: 10px;
    display: none;
    width: 100%;
}

.product-widgets__item:hover .whf-detail__scores-table {
    display: block;
}
;@import "sass-embedded-legacy-load-done:928";