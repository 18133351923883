/* ------------------------------------ *\
    BLOCKQUOTES
\* ------------------------------------ */

blockquote {

    &::before,
    &::after {
        font-family: $font-family--font-awesome;
        color: $grey;
    }

    &::before {
        content: $fa-var-quote-left; //.fa-quote-left
        margin-right: $space;
    }

    &::after {
        content: $fa-var-quote-right; //.fa-quote-right
        margin-left: $space;
    }
}

;@import "sass-embedded-legacy-load-done:214";