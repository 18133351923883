.stretch {
    margin-left: -($space * 2);
    margin-right: -($space * 2);

    @include media(lg) {
        margin-left: inherit;
        margin-right: inherit;
    }
}

@include generate-breakpoint-prefixes($class-name: 'float') {
    &--right { float: right; }
    &--left { float: left; }
}

.block { display: block; }
.relative { position: relative; }
.fixed { position: fixed; }
.inline--block { display: inline-block !important; }
.center,
.center--vertical { position: absolute !important; }

.center {
    top: 50% !important;
    left: 50% !important;
    transform: translate3d(-50%, -50%, 0) !important;
}
.center--vertical {
    top: 50% !important;
    transform: translate3d(0, -50%, 0) !important;
}
.pointer { cursor: pointer; }
.no-click { pointer-events: none; }
._z1 { z-index: $z1; }
._z2 { z-index: $z2; }
._z3 { z-index: $z3; }
._z4 { z-index: $z4; }

.overflow--hidden { overflow: hidden; }
.o-visible {
    overflow: visible !important;
}

;@import "sass-embedded-legacy-load-done:162";