
/* ------------------------------------ *\
    BORDERS
\* ------------------------------------ */

@include generate-breakpoint-prefixes($class-name: '_b', $short-name: true) {
    & { border: 1px solid $border-default-color; }
    &x {
        border-left: 1px solid $border-default-color;
        border-right: 1px solid $border-default-color;
    }
    &y {
        border-top: 1px solid $border-default-color;
        border-bottom: 1px solid $border-default-color;
    }
    &t { border-top: 1px solid $border-default-color; }
    &r { border-right: 1px solid $border-default-color; }
    &b { border-bottom: 1px solid $border-default-color; }
    &l { border-left: 1px solid $border-default-color; }

    &0 { border: 0; }
    &t0 { border-top: 0; }
    &r0 { border-right: 0; }
    &b0 { border-bottom: 0; }
    &l0 { border-left: 0; }
    &x0 {
        border-left: 0;
        border-right: 0;
    }
    &y0 {
        border-top: 0;
        border-bottom: 0;
    }
}

._b--light { border-color: $grey--light; }
._bo { border-radius: $border-radius; }
._bot { border-radius: $border-radius $border-radius 0 0; }
._bor { border-radius: 0 $border-radius $border-radius 0; }
._bob { border-radius: 0 0 $border-radius $border-radius; }
._bol { border-radius: $border-radius 0 0 $border-radius; }
._b-circle { border-radius: 50%; }

;@import "sass-embedded-legacy-load-done:146";