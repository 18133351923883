.m-banner {
    &--vertical-margin {
        margin: ($space * 3) 0 ($space * 6) 0;
    }

    &--mobile-only {
        display: block;
    }

    &--lg-only {
        display: none;
    }
}

;@import "sass-embedded-legacy-load-done:981";