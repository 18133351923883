.progress-bar {
    justify-content: space-between;
    width: 100%;
    background-color: $grey--navigation;
    align-items: center;
    display: flex;

    &--order-cancel {
        .progress-bar__step-text {
            color: $grey--dark;
        }

        background-color: #fff;
    }

    &__container {
        align-items: center;
        display: flex;
        padding-top: $space * 4;
        padding-bottom: $space * 4;
        justify-content: space-between;

        @include media(md) {
            padding-top: $space * 3;
            padding-bottom: $space * 3;
        }
    }

    &__step-separator {
        opacity: 0.3;
        margin-left: $space * 2;
        margin-right: $space * 2;
        width: 100%;
        display: none;
        visibility: hidden;

        @include border($where: 'bottom');

        @include media(lg) {
            display: block;
            visibility: visible;
            top: 38px;
            -ms-flex: 1;
        }
    }

    &__step-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: $grey--dark;
        color: #fff;
        font-size: $font-size--small;
        border-radius: 50%;
        font-weight: $font-weight--bold;
        margin: auto;
        margin-right: $space * 2;

        @include media(lg) {
            width: 36px;
            height: 36px;
            font-size: $font-size--medium;
        }

        .fa {
            display: none;
        }
    }

    &__step-text {
        font-size: $font-size--small;
        line-height: $line-height;
        max-width: 65px;
        color: $grey;

        @include media(lg) {
            font-size: $font-size--regular-plus;
            max-width: unset;
            white-space: nowrap;
        }
    }

    &__step {
        align-items: center;
        display: flex;
        -ms-flex: 1;

        &.active {
            .progress-bar__step-icon {
                background-color: $blue;
                color: #fff;
            }

            .progress-bar__step-text {
                font-weight: $font-weight--bold;
            }
        }

        &.active:not(.current) {
            .progress-bar__step-icon {
                background-color: $green;
            }

            .progress-bar__step-icon span:first-child {
                display: none;
            }

            .progress-bar__step-icon .fa {
                display: block;
            }

            .progress-bar__step-text {
                font-weight: $font-weight--base;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1039";