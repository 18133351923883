.t-hero-carousel {
    width: 100%;
    position: relative;
    padding-bottom: $space * 8;
    overflow: hidden;

    &__wrapper {
        position: relative;
    }

    &__title {
        margin-bottom: $space * 6;
        position: relative;
        z-index: $z5;
    }

    &--indicator-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }

    &--indicator {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .circle-dot {
            width: 12px;
            height: 12px;
            display: block;
            border-radius: 50%;
            background: $grey;
        }
    }

    &--indicator.active {
        .circle-dot {
            width: 14px;
            height: 14px;
            background: $blue;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        width: inherit;
        pointer-events: auto;
        touch-action: pan-y;

        &.smooth {
            transition: all 0.3s ease-in-out;
        }

        &--swipeable {
            overflow: hidden;
        }
    }

    &--navigation-wrapper {
        position: absolute;
        bottom: 50px;
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        top: 0;
        display: none;
        pointer-events: none;
    }

    &--navigation-container {
        position: relative;
    }

    &--navigation-rectangle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 9px;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: auto;
    }

    &--navigation-rectangle-inner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--navigation-rectangle-item {
        height: 48px;
        padding: 0 ($space * 4);
        display: flex;
        justify-content: center;
        align-items: center;
        @include text-variant(small-plus);
        color: white;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px;
            border-color: transparent transparent rgba(0, 0, 0, 0.7);
            top: -20px;
            visibility: hidden;
        }
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px;
            border-color: transparent transparent white;
            top: -10px;
            visibility: hidden;
        }
    }

    &--navigation-rectangle-item.active {
        font-weight: bold;
        &::before {
            visibility: visible;
            transition: all 0.5s ease-in-out;
        }
        &::after {
            visibility: visible;
            transition: all 0.5s ease-in-out;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        visibility: hidden;
        transition: all 0.2s;
        margin-left: 50px;
        margin-right: 50px;
    }

    &__button-placeholder {
        height: 56px;
        flex-shrink: 0;
    }

    &__button-left.button,
    &__button-right.button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
        box-shadow: none;
        padding: 0;
        border: none;
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 0;

        .fa {
            font-size: 108px;
            width: 70px;
            height: 70px;
            color: white;
        }

        @include media(md) {
            display: block;
        }
    }

    &__button-left.button:not(:disabled),
    &__button-right.button:not(:disabled) {
        border: none;
    }

    &__button-left.button {
        left: 0;
    }

    &__button-right.button {
        right: 0;
    }
}

;@import "sass-embedded-legacy-load-done:1474";