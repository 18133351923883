@include media(md) {
    .t-cart-v2-item {
        padding: $space * 6;

        &__info-and-price {
            width: 100%;
        }

        //&__quantity-form {
        //    width: 150px;
        //}
    }
}

;@import "sass-embedded-legacy-load-done:863";