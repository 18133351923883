.form__field {
    align-items: center;
    display: flex;
    position: relative;
    flex-wrap: wrap;

    &--checkbox,
    &--radio {
        flex-wrap: nowrap;
        margin-left: $space * 2;

        &,
        label {
            cursor: pointer;
        }

        label {
            margin-left: $space * 2;
            margin-bottom: 0;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &--hidden {
        display: none;
    }

    &--group {
        flex-wrap: wrap;
    }

    &--group &--radio,
    &--group &--checkbox {
        min-width: 100%;
        margin-left: 0;
        margin-bottom: $space * 2;
    }

    &--switch {
        /** TODO: check why !important is needed */
        input[type="checkbox"] {
            min-width: 35px !important;
            width: 35px !important;
            height: 16px !important;

            + span {
                background-color: $grey;
                border-radius: 8px;
                border: 0;
                max-height: 16px;
                max-width: 35px;
                min-height: 16px;
                min-width: 35px;
                transition: 0.4s;
            }

            + span::before {
                background-color: $grey--light;
                border-radius: 50%;
                box-shadow: $shadow1;
                bottom: -1px;
                content: "";
                display: block;
                height: 20px;
                left: -1px;
                position: absolute;
                top: -3px;
                transition: 0.4s;
                transform: none;
                width: 20px;
            }

            &:checked + span {
                background-color: $color--success;
            }

            &:checked + span::before {
                left: calc(100% - 19px);
            }
        }
    }

    &--select,
    &--email,
    &--text,
    &--password {
        .float-label__target-input {
            padding-top: 20px;
            padding-bottom: 7px;
        }
    }

    &--tooltip-centered .tooltip {
        left: 50%;
        transform: translateX(-50%);
    }

    &--disabled {
        &-label {
            opacity: 0.5;
            cursor: not-allowed;
        }

        color: $grey;
    }
}

;@import "sass-embedded-legacy-load-done:781";