.ca-order {
    margin-top: $space * 3;

    @include media(md) {
        margin-top: $space * 3;
    }

    background-color: #fff;
    box-shadow: $shadow6;

    .ca-announcement + & {
        margin-top: 0;
    }

    .tag + .tag {
        margin-left: $space;
    }

    &__column {
        position: relative;
        padding: $space * 3;
    }

    &__shipment {
        @include border(top, $color: $grey--light);
        background-color: $grey--lighter;

        h3 {
            font-weight: 600;
        }

        h3 small {
            font-size: 0.8em;
        }

        .js-collapsible .fa {
            position: absolute;
            top: 50%;
            right: $space * 3;
            transform: translateY(-50%);
            color: $theme-color;
            font-size: $font-size--medium;
        }

        .fa-chevron-down {
            display: none;
        }

        .collapsed .fa-chevron-down {
            display: block;
        }

        .collapsed .fa-chevron-up {
            display: none;
        }

        &:first-child {
            border-top: 0;
        }
    }

    &__items-header {
        @include border(bottom);
        display: none;
        color: $grey--dark;
        font-weight: 600;
    }

    &__item-image {
        margin-right: $space * 3;

        &,
        img {
            min-width: 48px;
            max-width: 48px;
        }

        &--placeholder {
            width: 48px;
            height: 48px;
            background: $grey--medium;
            margin-top: $space * 2;
        }
    }

    &__item {
        @include border(bottom, $color: $grey--light);
    }

    &__item &__column {
        padding-bottom: 0;

        &:last-child {
            padding-top: 0;
            padding-bottom: $space * 3;
            margin-left: auto;
        }
    }

    [data-collapsible].collapsed {
        display: none;
    }
}

@include media(xxl) {
    .ca-order {
        .tag + .tag {
            margin-left: 0;
        }

        &__items-header {
            display: block;
        }

        &__item &__column {
            padding-bottom: $space * 3;

            &:last-child {
                padding-top: $space * 3;
            }
        }

        &__item.collapsed {
            display: flex;
        }

        [data-collapsible].collapsed {
            display: block;
        }

        [data-collapsible].collapsed.flex {
            display: flex;
        }
    }
}

;@import "sass-embedded-legacy-load-done:670";