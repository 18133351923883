//Refactors: styles/v2/components/_quantity-widget.scss

/* ----------------------------------------------- *\
    QUANTITY WIDGET (when the button is present)
\* ----------------------------------------------- */

$quantity-widget-height: $space * 8;

.m-quantity-widget {
    width: 100%;

    &__button--buy {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        height: $quantity-widget-height;
        padding: 7px 8px;
        width: percentage(calc(1 / 4));

        [class^="icon--"] {
            margin: -2px;
        }
    }

    .m-quantity-field {
        border-right: 0;
        max-height: 40px;
        width: percentage(calc(3 / 4));

        &__button--up {
            border-radius: 0;
        }
    }
}
$quantity-widget-height-v2: $space * 10;


/* ------------------------------------ *\
    QUANTITY FIELD (+/-)
\* ------------------------------------ */

.m-quantity-field,
.m-quantity-field-v2 {
    align-items: center;
    display: flex;
    position: relative;
    width: $space * 25;

    &__input,
    &__button {
        @include border;
        background-color: #fff;
        color: $base-text-color;
        height: $quantity-widget-height;
        text-align: center;
    }

    &__input {
        border-radius: 0;
        border-width: 1px 0;
        font-weight: $font-weight--bold;
        width: calc(100% - 80px);
        &.square-abtest29887 {
            width: $space * 10;
        }

        //remove the native arrows
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        &.touched {
            &.valid {
                background-image: none;
            }
        }

        -moz-appearance: textfield;
    }

    &__button {
        background-color: #fff;
        border-radius: 0;
        color: $theme-color;
        padding: 11px 0;
        width: 40px;

        &.square-abtest29887 {
            width: $space * 10;
        }

        &:hover {
            color: $theme-color;
            text-decoration: none;
        }

        &[disabled] {
            color: $grey;
            cursor: not-allowed;
        }

        &__button--down {
            border-bottom-left-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }

        &__button--up {
            border-bottom-right-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
    }
}

.m-quantity-field {
    &__button {
        width: 30%;
    }
}

.m-quantity-field-v2 {
    width: 100%;
    height: $quantity-widget-height-v2;

    &__input,
    &__button {
        height: $quantity-widget-height-v2;
    }

    &__input {
        font-size: $font-size--medium;

        &:focus {
            border-width: 1px;
        }
    }

    &__button {
        .fa {
            font-size: $font-size--medium-plus;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1041";